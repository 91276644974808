html {
  position: relative;
  min-height: 100%;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

.header {
  background: #121212;
  color: #999;
  overflow: hidden;
  padding: 24px;
}

.header > .logo > img {
  height: 70px;
}

.header > .auth-panel {
  float: right;
}

.header > .auth-panel .btn {
  border: 1px solid #2f2f2f;
  color: #999;
  margin-left: 8px;
}

.header > .auth-panel .btn:focus {
  box-shadow: 0 0 8px rgba(47,47,47,0.9);
}

.header > .auth-panel .btn.btn-login {
  background: #2f2f2f;
}

.header > .auth-panel .btn.btn-login:hover {
  background: #464646;
}

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 16px 0 0 16px;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li + li:before {
  color: #767676;
  content: "/\00a0";
  padding: 0 4px;
}

/* Temp */
.breadcrumb + .workarea {
  padding-top: 0;
}

.workarea {
  min-height: 240px;
  padding: 16px;
}

body > footer {
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  color: #767676;
}

body > footer a {
  color: #767676;
}

body > footer a.active {
  color: #ccc;
  text-decoration: none;
}

body > footer > .languages > ul {
  color: #767676;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body > footer > .languages li {
  display: inline-block;
  margin: 0 4px;
}

body > footer > .languages li:first-child {
  margin: 0;
}

@media screen and (max-width: 512px) {
  .header {
    display: none;
  }

  body > footer {
    padding: 16px;
    text-align: center;
  }

  body > footer > .languages li,
  body > footer > .copy,
  body > footer > .powered {
    line-height: 22px;
  }
}

@media screen and (min-width: 512px) {
  body > footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  body > footer > .languages,
  body > footer > .copy,
  body > footer > .powered {
    padding: 22px;
  }

  body > footer > .copy {
    text-align: center;
  }

  body > footer > .powered {
    text-align: right;
  }
}
